@import url('https://fonts.cdnfonts.com/css/avenir-lt-std');

* {
    margin: 0;
    padding: 0;
    font-family: 'Avenir LT Std', sans-serif;
}

body {
    background-color: #f6f6f6;
}

@import "home/home";
@import "footer/footer";
