.fade-in {
    opacity: 1 !important;
    transition: opacity 0.8s ease-in !important;
}

#content-centered {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 7.5in;
    padding: 0 0.5in;
    opacity: 0;

    @media screen and (max-width: 725px) {
        padding: 0 0.3in;
    }

    #anapad-icon {
        max-width: 2in;
        margin-top: 1.75in;
        margin-bottom: 0.75in;

        @media screen and (max-width: 725px) {
            width: 90%;
            margin-top: 1in;
            margin-bottom: 0.5in;
        }
    }

    h1 {
        margin-bottom: 0.3in;
    }

    #tab {
        display: inline-block;
        min-width: 4ch;
    }

    p {
        text-align: left;
        font-size: 13pt;
        line-height: 2em;
        margin-bottom: 0.25in;
    }

    #links {
        margin-top: 0.5in;

        img {
            width: 2.5em;
        }

        a {
            margin: 0 0.2in;
        }

        button {
            all: unset;
            cursor: pointer;
            margin: 0.2in;
            text-align: center;
        }

        #email-button-element-stack {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .animation-up {
            opacity: 1 !important;
            transform: translateY(-0.45in) !important;
        }

        #email-copied-text {
            z-index: -1;
            position: absolute;
            margin: 0;
            padding: 0.075in;
            width: 0.75in;

            font-size: 10pt;
            opacity: 0;

            transform: translateY(-2em);
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
}
